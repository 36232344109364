import {on} from 'delegated-events'

/*
 *  Used to show hidden items in a grid of items. For isntance if
 *  you have a grid of 9 items and you want to display only 3 along
 *  with a button which on click will dissapear and make the remaining
 *  6 items visible.
 *
 *  ```
 *  <div class="js-unveil-items">
 *   <div class="js-unveil-item js-unveil-item-visible">...</div>
 *   <div class="js-unveil-item js-unveil-item-visible">...</div>
 *   <div class="js-unveil-item js-unveil-item-visible">...</div>
 *   <div class="js-unveil-item" hidden>...</div>
 *   <div class="js-unveil-item" hidden>...</div>
 *   <div class="js-unveil-item" hidden>...</div>
 *   <div class="js-unveil-item" hidden>...</div>
 *   <div class="js-unveil-item" hidden>...</div>
 *   <div class="js-unveil-item" hidden>...</div>
 *
 *   <button type="button" class="js-unveil">Show All Items</button>
 *  </div>
 *  ```
 *
 *  @see `/features` and `/about/develper-policy` for example of usage
 */
on('click', '.js-unveil', function (event: Event) {
  event.preventDefault()
  const target = <HTMLElement>event.currentTarget
  const itemsContainer = target.closest<HTMLElement>('.js-unveil-items')!

  if (!itemsContainer) {
    return
  }
  target.remove()

  const hiddenItems = itemsContainer.querySelectorAll('.js-unveil-item:not(.js-unveil-item-visible)')

  for (const item of hiddenItems) {
    item.classList.add('js-unveil-item-visible')
    item.removeAttribute('hidden')
  }

  if (document.body.classList.contains('intent-mouse')) {
    return
  }

  // focus the first link element that was previusly invisible
  if (hiddenItems.length) {
    const firstHiddenItem = <HTMLElement>hiddenItems[0]
    const focusableElement =
      firstHiddenItem.hasAttribute('tabindex') ||
      firstHiddenItem.tagName === 'A' ||
      firstHiddenItem.tagName === 'BUTTON'
        ? firstHiddenItem
        : <HTMLElement>firstHiddenItem.querySelector('[tabindex], a, button')

    focusableElement?.focus()
  }
})
